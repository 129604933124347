body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.ReactTags__tags {
  position: relative;
}

div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  padding: 0 4px;
}

div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

div.breadcrumb {
  background-color: white;
  width: 1127px;
  margin-left: auto!important;
  margin-right: auto!important;
}

.ui.container {
    width: 93%;
}

input.ant-select-selection-search-input {
  border: none!important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
}

.ant-btn-primary {
  background: #00c2b2;
  border-color: #00c2b2;
}

.ant-btn-primary:hover {
  background: #00b3a4;
  border-color: #00b3a4;
}

.ant-switch-checked {
  background-color: #00c2b2;
}

.ant-dropdown-menu-item-selected {
  color: #00c2b2;
}

.ant-tag-blue {
  color: #00b3a4;
  background: #e6f7ff;
  border-color: #f0f2f2;
}

.ant-table-filter-trigger.active {
  color: #00b3a4;
}

.ant-pagination-item-active a {
  color: #00c2b2;
  border-color: #00c2b2;
}

.ant-pagination-item-active {
  border-color: #00c2b2;
}

.ant-spin {
  color: #00c2b2;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00c2b2;
}

.ui.breadcrumb a {
  color: #00b3a4;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00c2b2;
  border-color: #00b3a4;
}

.ant-btn-link {
  color: #00b3a4;
}

.ant-spin-dot-item {
  background-color: #00b3a4;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #00b3a4;
  border-color: #00b3a4;
}
.ant-badge{
  display: block;
}

.scrollToTop{
  position:fixed;
  right:3px;
  bottom:25%;
  width:40px;
  height: 40px;
  border-width: 0;
  border-radius: 2px;
  background: #eeeeee;
  color: #666666;
}
.scrollToTop:hover{
  cursor: pointer;
}

.ui.input {
  width: 100%;
}

.ui.search{
  min-width: 30%;
}

.ui.search>.results {
  width: 100%;
}

.wrap-component-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.ag-theme-alpine .ag-header-cell-text {
  font-weight: 600;
  font-size: 16px !important;
}

.ag-theme-alpine{
  height: 87vh;
  width: 70%;
  margin-left: 15%;
  margin-top:15px;

}

/* Customizing the style of the noRowsToShow text */
.ag-theme-balham .ag-overlay-no-rows-center {
  font-size: 18px;
  color: red;
}

/* Customizing the style of the loading text */
.ag-theme-balham .ag-overlay-loading-center {
  font-size: 18px;
  color: blue;
}
.feature-flag-selectable:hover{
  background-color: #d3e5f2;
}

.feature-flag-clickable-area:hover{
  cursor: pointer;
}