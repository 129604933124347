@import '~antd/dist/antd.css';

.site-collapse-custom-collapse .site-collapse-custom-panel {
  display: flex;
  flex-direction: column;    
}


.site-collapse-custom-collapse > .site-collapse-custom-panel > .ant-collapse-header {
    padding: 0px 16px;
}
.site-collapse-custom-collapse > .site-collapse-custom-panel > .ant-collapse-header > .ant-collapse-extra {
    display: flex;
    flex: 3;
    height: 100%;
}
.site-collapse-custom-panel > .ant-collapse-header {
    display: flex;
    flex: 1;
    padding: 0px 16px;
    align-items: center;
    justify-items: center;
}
  